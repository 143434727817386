import React, { Fragment } from 'react';

import Contact from '../components/contact-us/vende';
import Ubication from '../components/ubication';

export default ()=> {

  return(
    <Fragment>
      <Contact />
      <Ubication />
    </Fragment>
  )
}